import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { useMediaQuery } from 'react-responsive';
import styles from './ChatApp.module.css';
import mixpanel from 'mixpanel-browser';

const ChatHistory = ({ messages, isTyping, getToken, isAuthenticated }) => {
    const chatHistoryRef = useRef(null);
    const [hoveredMessageIndex, setHoveredMessageIndex] = useState(null);
    const [hasLoadedInitialMessages, setHasLoadedInitialMessages] = useState(false); // Track initial load
    const [showToast, setShowToast] = useState(false);
    const apiUrl = process.env.REACT_APP_BACK_END_URL;
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuMessage, setContextMenuMessage] = useState(null);
    const pressTimer = useRef(null);

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    // Case 1: Auto-scroll to the bottom when messages are initially loaded (without animation)
    useEffect(() => {
        if (!hasLoadedInitialMessages && messages.length > 0) {
            if (chatHistoryRef.current) {
                chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
            }
            setHasLoadedInitialMessages(true); // Set to true after the first load
        }
    }, [messages]); // Only dependent on `messages`, but runs just once

    // Case 2 and 3: Scroll smoothly when a new message is added or bot responds
    useEffect(() => {
        if (hasLoadedInitialMessages && chatHistoryRef.current) {
            chatHistoryRef.current.scrollTo({
                top: chatHistoryRef.current.scrollHeight,
                behavior: 'smooth', // Smooth scroll for subsequent messages
            });
        }
    }, [messages, isTyping]); // Trigger smooth scroll for new messages or typing changes

    const handleLongPressStart = (message) => {
        pressTimer.current = setTimeout(() => {
            setContextMenuMessage(message);
            setShowContextMenu(true);
        }, 1000);
    };

    const handlePressEnd = () => {
        clearTimeout(pressTimer.current);
    }

    const handleSaveRecipe = async (recipeContent) => {

        mixpanel.track('Recipe Saved', {
            content: recipeContent
        });

        try {
            const token = await getToken();  // Fetch user token for authentication

            const response = await fetch(`${apiUrl}/api/save-recipe`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,  // Add the token to the request
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    content: recipeContent,  // Save the full recipe content
                }),
            });

            const result = await response.json();
            if (result.success) {
                setShowToast(true);
                setTimeout(() => setShowToast(false), 3000);
            } else {
                alert('Failed to save the recipe.');
            }
        } catch (error) {
            console.error('Error saving recipe:', error);
            alert('Error saving recipe.');
        }
    };

    return (
        <div className={styles.chatHistory} ref={chatHistoryRef}>

            {showToast && (
                <div className={styles.toastNotification}>
                    <img src="/checkmark_icon.svg" alt="Checkmark Icon" className={styles.checkmarkIcon} />
                    <div>Saved to your cookbook!</div>
                </div>
            )}

            {showContextMenu && isMobile && (
                <div className={styles.contextMenu}>
                    <button
                        className={styles.contextMenuSaveButton}
                        onClick={() => {
                            handleSaveRecipe(contextMenuMessage);
                            setShowContextMenu(false);
                        }}
                    >
                        Save to Cookbook
                    </button>
                    <button
                        className={styles.contextMenuCancelButton}
                        onClick={() => setShowContextMenu(false)}
                    >
                        Cancel
                    </button>
                </div>
            )}

            {messages.map((msg, index) => (
                <div 
                    key={index}
                    className={msg.sender === 'user' ? styles.userMessageWrapper : styles.botMessageWrapper}
                    
                    onMouseEnter={() => !isMobile && setHoveredMessageIndex(index)}
                    onMouseLeave={() => !isMobile && setHoveredMessageIndex(null)}

                    onTouchStart={isMobile && isAuthenticated ? () => handleLongPressStart(msg.text) : null}
                    onTouchEnd={isMobile && isAuthenticated ? handlePressEnd : null}
                >
                    <div className={msg.sender === 'user' ? styles.userMessage : styles.botMessage}>
                        {msg.sender === 'bot' ? (
                            <ReactMarkdown
                                components={{
                                    p: ({ node, ...props }) => <span {...props} />
                                }}
                            >
                                {msg.text}
                            </ReactMarkdown>
                        ) : (
                            msg.text
                        )}
                    </div>

                    {msg.sender === 'bot' && hoveredMessageIndex === index && !isMobile && isAuthenticated && (
                        <button 
                            className={styles.saveMessageButton}
                            onClick={() => handleSaveRecipe(msg.text)}
                        >
                            Save
                        </button>
                    )}
                </div>
            ))}
            {isTyping && (
                <div className={styles.botMessageWrapper}>
                    <div className={styles.botMessage}>
                        <div className={styles.typingIndicator}>
                            <span className={styles.dot}>.</span>
                            <span className={styles.dot}>.</span>
                            <span className={styles.dot}>.</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(ChatHistory);