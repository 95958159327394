import React, { useState, useEffect, useRef } from 'react';
import styles from './ChatApp.module.css';
import { useMediaQuery } from 'react-responsive';

const ChatInput = ({ handleSend, className }) => {
    const [input, setInput] = useState('');
    const [placeholder, setPlaceholder] = useState('Generate a recipe, ask a cooking question...');
    const inputRef = useRef(null);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        if (!isMobile) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        // Check the screen width when the component mounts
        const updatePlaceholder = () => {
            if (window.innerWidth <= 768) {
                setPlaceholder('Generate a recipe, ask a question...'); // Mobile-specific placeholder
            } else {
                setPlaceholder('Generate a recipe, ask a cooking question...'); // Default for larger screens
            }
        };
        updatePlaceholder();
        window.addEventListener('resize', updatePlaceholder);
        return () => window.removeEventListener('resize', updatePlaceholder);
    }, []);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSend(input)
            setInput('');
        }
    };


    return (
        <div className={styles.chatInput + ' ' + className}>
            <input
                name="non_password_field"
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder={placeholder}
                autoComplete="off"
                autoCorrect="on"
                autoCapitalize="sentences"
                spellCheck={true}
                ref={inputRef}
            />
            <button onClick={() => { handleSend(input); setInput(''); }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    width="24" height="24"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 19V5m-7 7l7-7 7 7" />
                </svg>
            </button>
        </div>
    );
};

export default ChatInput;