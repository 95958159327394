const fetchChatHistory = async (token, apiUrl, numberOfLoadedMessages) => {
    try {

        const response = await fetch(`${apiUrl}/api/fetchMessages?skip=${numberOfLoadedMessages}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 404) {
            console.log('No chat history found');
            return {fetchedMessages: [], fetchedHasSubmitted: false };
        }

        if (!response.ok) {
            throw new Error(`Error fetching chat history: ${response.statusText}`);
        }

        const data = await response.json();

        return { fetchedMessages: data, fetchedHasSubmitted: data.length > 0 };

    } catch (error) {
        console.error('Error loading chat history: ', error);
        throw error;
    }
};

export default fetchChatHistory;