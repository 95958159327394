import React, { useState, useEffect } from 'react';
import styles from './Ingredients.module.css';
import WebsiteHeader from './WebsiteHeader';
import { useAuth0 } from '@auth0/auth0-react';
import ReactMarkdown from 'react-markdown';
import mixpanel from 'mixpanel-browser';

const Ingredients = () => {

    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [showUploadView, setShowUploadView] = useState(true);
    const { getAccessTokenSilently } = useAuth0();
    const apiUrl = process.env.REACT_APP_BACK_END_URL;
    const auth0ApiAudience = process.env.REACT_APP_AUTH0_API_AUDIENCE;

    const getToken = async () => {
        try {
            const token = await getAccessTokenSilently({
                authorizationParams: {
                    audience: auth0ApiAudience
                }
            });
            return token;
        } catch (error) {
            console.error('Error fetching token:', error);
            throw error;
        }
    };

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        if (files.length === 0) return;

        setLoading(true);
        setResponse('');
        setShowUploadView(false);

        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append('image', file);
        });
        

        try {
            const token = await getToken();

            const response = await fetch(`${apiUrl}/api/upload-image`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                },
                body: formData,
            });

            const data = await response.json();
            if (data.success) {
                setResponse(data.response);
                mixpanel.track('Image(s) Uploaded', {
                    image_quantity: files.length,
                    response: data.response,
                });
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.error('Error processing images:', error);
            alert('Failed to process the images. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleBackButtonClick = () => {
        setShowUploadView(true); // Return to the upload view
        setResponse(''); // Clear the response
    };

    useEffect(() => {
        mixpanel.track('Page Viewed', {
            page: 'Ingredients'
        });
    }, []);

    return (
        <div className={styles.container}>
            <WebsiteHeader getToken={getToken} />
            <div className={styles.content}>
                {showUploadView && (
                    <>
                        <h1 className={styles.initialPrompt}>What ingredients do you have?</h1>
                        <p className={styles.subtext}>Upload some photos of your fridge and pantry so we know what you have on hand.</p>
                        <input
                            type="file"
                            id="fileInput"
                            className={styles.fileInput}
                            accept="image/*"
                            multiple
                            onChange={handleFileChange}
                        />
                        <label htmlFor="fileInput" className={styles.uploadButton}>
                            Take or Upload Photos
                        </label>
                    </>
                )}
                {loading && <p className={styles.processingText}>Processing...</p>}
                {!showUploadView && !loading && response && (
                    <>
                        <button className={styles.backButton} onClick={handleBackButtonClick}>
                            Back
                        </button>
                        <div className={styles.response}>
                            <ReactMarkdown>{response}</ReactMarkdown>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Ingredients;