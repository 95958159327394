import React, { useEffect, useState } from 'react';
import styles from './MyCookbook.module.css';
import WebsiteHeader from './WebsiteHeader';
import { useAuth0 } from '@auth0/auth0-react';
import ReactMarkdown from 'react-markdown';
import { useMediaQuery } from 'react-responsive';
import mixpanel from 'mixpanel-browser';

const MyCookbook = () => {
    const [recipes, setRecipes] = useState([]);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_BACK_END_URL;
    const auth0ApiAudience = process.env.REACT_APP_AUTH0_API_AUDIENCE;
    const { getAccessTokenSilently } = useAuth0();
    const [noteText, setNoteText] = useState('');
    const [noteLoading, setNoteLoading] = useState(false);

    const isMobile = useMediaQuery({ query: '(max-width: 768px)'});

    const [visibleViews, setVisibleViews] = useState(() =>
        isMobile ? ['recipeList'] : ['recipeList', 'recipeDetails']
    );

    const getToken = async () => {
        try {
            const token = await getAccessTokenSilently({
                authorizationParams: {
                    audience: auth0ApiAudience
                }
            });
            return token;
        } catch (error) {
            console.error('Error fetching token:', error);
            throw error;
        }
    };

    const fetchNote = async () => {
        if (!selectedRecipe) return;
        setNoteLoading(true);

        try {
            const token = await getToken();
            const response = await fetch(`${apiUrl}/api/fetch-note/${selectedRecipe._id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const result = await response.json();

            if (result.success) {
                setNoteText(result.note?.text || '');
            } else {
                console.error('Failed to fetch note:', result.message);
            }
        } catch (error) {
            console.error('Error fetching note:', error);
        } finally {
            setNoteLoading(false);
        }
    };

    const saveNote = async () => {
        if (!selectedRecipe) return;

        try {
            const token = await getToken();
            const response = await fetch(`${apiUrl}/api/save-note`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    recipeId: selectedRecipe._id,
                    noteText,
                }),
            });

            const result = await response.json();

            if (result.success) {
                console.log('Note saved successfully:', result.note);
                mixpanel.track('Note Saved', {
                    content: noteText
                });
                closeNotesDrawer();
            } else {
                console.error('Failed to save note:', result.message);
            }
        } catch (error) {
            console.error('Error saving note:', error);
        }
    };

    const showView = (view) => {
        setVisibleViews((prev) => [...prev, view]); // Add view to the array
    };

    const hideView = (view) => {
        setVisibleViews((prev) => prev.filter((v) => v !== view)); // Remove view
    };

    const toggleView = (view) => {
        setVisibleViews((prev) =>
            prev.includes(view) ? prev.filter((v) => v !== view) : [...prev, view]
        );
    };

    // Trigger fetch when opening the drawer
    useEffect(() => {
        if (visibleViews.includes('notes')) {
            fetchNote();
        }
    }, [visibleViews]);

    useEffect(() => {
        console.log('The visible views are ' + visibleViews);
    })

    const closeNotesDrawer = () => {
        const drawer = document.querySelector('#notesDrawer');
        drawer.style.transform = 'translateX(100%)';

        if (isMobile) {
            hideView('notes');
            showView('recipeDetails');
        } else {
            setTimeout(() => {
                hideView('notes');
            }, 300);
        }
    };

    const toggleNotesDrawer = () => {
        if (isMobile) {
            hideView('recipeDetails');
        }
        toggleView('notes');
    };

    useEffect(() => {
        if (visibleViews.includes('notes')) {
            setTimeout(() => {
                const drawer = document.querySelector('#notesDrawer');
                console.log('Drawer is' + drawer);
                const isOpen = drawer.style.transform === 'translateX(0)';
                drawer.style.transform = isOpen ? 'translateX(100%)' : 'translateX(0)';
            }, 1);
        }
    }, [visibleViews]);

    // Fetch the saved recipes from the back-end API when the component is mounted
    useEffect(() => {

        mixpanel.track('Page Viewed', {
            page: 'My Cookbook'
        });

        const fetchRecipes = async () => {
            try {
                const token = await getToken();  // Fetch the user token for authentication

                const response = await fetch(`${apiUrl}/api/get-recipes`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,  // Include the JWT token
                    },
                });

                const result = await response.json();

                if (result.success) {
                    setRecipes(result.recipes);
                    if (!selectedRecipe && result.recipes.length > 0) {
                        setSelectedRecipe(result.recipes[0]);  // Set the first recipe as selected by default
                    }
                   console.log("Successfully fetched the recipes.");
                } else {
                    console.error('Failed to fetch recipes');
                }
            } catch (error) {
                console.error('Error fetching recipes:', error);
            } finally {
                setLoading(false);  // Hide the loading indicator once the fetch is done
            }
        };

        fetchRecipes();
    }, []);

    if (loading) {
        return <div>Loading...</div>;  // Display a loading message while fetching
    }

    return (
        <div className={styles.container}>
            <WebsiteHeader getToken={getToken} />

            {isMobile && (

                /*Enter code for mobile screen here*/
                <div className={styles.content}>

                    {visibleViews.includes('recipeList') && (
                        <div className={styles.recipeList}>
                            {recipes.length === 0 ? (
                                <p>No recipes saved yet.</p>
                            ) : (
                                recipes.map((recipe) => (
                                    <button
                                        key={recipe.id}
                                        onClick={() => {
                                            setSelectedRecipe(recipe);
                                            hideView('recipeList');
                                            showView('recipeDetails');
                                        }}
                                        className={styles.recipeItem}
                                    >
                                        {recipe.title}
                                    </button>
                                )))
                            }
                        </div>
                    )}


                    {visibleViews.includes('recipeDetails') && (
                        <div className={styles.recipeDetails}>
                            <div className={styles.mobileButtonContainer}>
                                <button
                                    onClick={() => {
                                        hideView('recipeDetails');
                                        showView('recipeList');
                                    }}
                                    className={styles.backButton}
                                >
                                    <img src="/back_icon.svg" alt="Back Icon" className={styles.backIconMobile} />
                                    Recipe List
                                </button>
                                <button 
                                    className={styles.notesButtonMobile}
                                    onClick={() => {
                                        toggleNotesDrawer();
                                        mixpanel.track('Notes Button Pressed');
                                    }}
                                >
                                    <img src="/notes_icon.svg" alt="Notes Icon" className={styles.notesIconMobile} />
                                    Notes
                                </button>
                            </div>
                            <ReactMarkdown>{selectedRecipe.content}</ReactMarkdown>
                        </div>
                    )}
                    

                    {visibleViews.includes('notes') && (
                        <div id="notesDrawer" className={styles.notesDrawerMobile}>
                            <h2>Notes</h2>
                            <h3>on {selectedRecipe?.title || 'Recipe'}</h3>
                            {noteLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <textarea
                                    className={styles.notesTextarea}
                                    value={noteText}
                                    onChange={(e) => setNoteText(e.target.value)}
                                    placeholder="Enter your notes on the recipe here..."
                                ></textarea>
                            )}
                            <button
                                className={styles.saveButton}
                                onClick={saveNote}
                            >
                                Save
                            </button>
                        </div>
                    )}

                </div>

            )
            }

            {!isMobile && (
                // Enter code for desktop screen here
                    <div className={styles.content}>
                        <div className={styles.recipeList}>
                            {recipes.length === 0 ? (
                                <p>No recipes saved yet.</p>
                            ) : (
                                recipes.map((recipe) => (
                                    <button
                                        key={recipe._id}
                                        onClick={() => {
                                            if (visibleViews.includes('notes')) {
                                                hideView('notes');
                                                setNoteText('');
                                            }
                                            setSelectedRecipe(recipe)
                                        }}
                                        className={styles.recipeItem}
                                    >
                                        {recipe.title}
                                    </button>
                                ))
                            )}
                        </div>
                        <div id="recipeDetails" className={styles.recipeDetails}>
                            {selectedRecipe ? (
                                <>
                                    <ReactMarkdown>{selectedRecipe.content}</ReactMarkdown>

                                    <button
                                        className={styles.notesButton}
                                        onClick={() => {
                                            toggleNotesDrawer();
                                            mixpanel.track('Notes Button Pressed');
                                        }}
                                    >
                                        <img src="/notes_icon.svg" alt="Notes Icon" className={styles.notesIcon} />
                                    </button>
                                </>
                            ) : (
                                <p>Select a recipe to view the details.</p>
                            )}
                        </div>

                        {visibleViews.includes('notes') && (
                            <div id="notesDrawer" className={styles.notesDrawer}>
                                <h2>Notes</h2>
                                <h3>on {selectedRecipe?.title || 'Recipe'}</h3>
                                {noteLoading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <textarea
                                        className={styles.notesTextarea}
                                        value={noteText}
                                        onChange={(e) => setNoteText(e.target.value)}
                                        placeholder="Enter your notes on the recipe here..."
                                    ></textarea>
                                )}
                                <button className={styles.saveButton} onClick={saveNote}>Save</button>
                            </div>
                        )}

                    </div >
            )}
        </div>
    );
};

export default MyCookbook;