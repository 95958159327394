import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './OrderPreview.module.css';
import mixpanel from 'mixpanel-browser';

const OrderPreview = () => {
    const apiUrl = process.env.REACT_APP_BACK_END_URL;
    const auth0ApiAudience = process.env.REACT_APP_AUTH0_API_AUDIENCE;
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const getToken = async () => {
        try {
            const token = await getAccessTokenSilently({
                authorizationParams: {
                    audience: auth0ApiAudience
                }
            });
            return token;
        } catch (error) {
            console.error('Error fetching token:', error);
            throw error;
        }
    };

    const handleCheckout = async () => {
        try {
            const token = await getToken();

            const response = await fetch(`${apiUrl}/api/create-checkout-session`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                throw new Error(errorResponse.error || 'Failed to create checkout session');
            }

            const { url } = await response.json();

            // Redirect to the Stripe checkout page
            window.location.href = url;
        } catch (error) {
            console.error('Error during checkout session creation:', error);
            alert('Failed to proceed to checkout. Please try again.');
        }
    };

    useEffect(() => {
        mixpanel.track('Page Viewed', {
            page: 'Order Preview'
        });
    }, []);

    return (
        <div className={styles.orderPreviewContainer}>
            <div className={styles.orderSummary}>
                <h1>Order Summary</h1>
                <p>You are subscribing to Alfalfa Plus.</p>
                <p className={styles.price}>Price: <strong>$5 per month</strong></p>
                <div className={styles.buttonGroup}>
                    <button className={styles.checkoutButton} onClick={handleCheckout}>Proceed to Checkout</button>
                    <button className={styles.backButton} onClick={() => navigate(-1)}>Go Back</button>
                </div>
            </div>
        </div>
    );
};

export default OrderPreview;