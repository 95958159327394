const sendMessage = async ({
    promptButtonMessage,
    input,
    messages,
    setMessages,
    setIsTyping,
    setHasSubmitted,
    isAuthenticated,
    token,
    apiUrl,
    user
}) => {
    const message = promptButtonMessage || input.trim();
    if (!message) return;

    setHasSubmitted(true);

    const userMessage = { sender: 'user', text: message };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setIsTyping(true);

    try {

        const headers = { 'Content-Type': 'application/json' };

        if (isAuthenticated && token) {
            headers['Authorization'] = `Bearer ${token}`;
            const response = await fetch(`${apiUrl}/api/sendAndSaveMessage`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ message: userMessage, userId: user.sub }),
            });

            const data = await response.json();
            const botResponse = { sender: 'bot', text: data.response };
            setMessages(prevMessages => [...prevMessages, botResponse]);

        } else {
            // Unauthenticated flow
            const response = await fetch(`${apiUrl}/api/sendMessageByUnauthenticatedUser`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ messages: [...messages, userMessage] }),
            });

            const data = await response.json();
            const botResponse = { sender: 'bot', text: data.response };
            setMessages(prevMessages => [...prevMessages, botResponse]);
        }

    } catch (error) {
        console.error('Error:', error);
        const errorResponse = { sender: 'bot', text: `Error: ${error.message}` };
        setMessages(prevMessages => [...prevMessages, errorResponse]);
    } finally {
        setIsTyping(false);
    }
};

export default sendMessage;